import { extendTheme } from "@chakra-ui/react"
import { breakpoints } from "@constants/breakpoints"
import colors from "./colors"
import fonts from "./fonts"
import fontSizes from "./fontSizes"
import letterSpacings from "./letterSpacings"
import lineHeights from "./lineHeights"
import sizes from "./sizes"
import space from "./space"

// Extend theme as needed
export default extendTheme({
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  sizes,
  space,
})
