import "nprogress/nprogress.css"
import "@styles/global.css"
import React from "react"
import { CSSReset, ChakraProvider } from "@chakra-ui/react"
import { AppProps } from "next/app"
import Router from "next/router"
import NProgress from "nprogress"
import theme from "@theme/index"

NProgress.configure({ showSpinner: false, trickleSpeed: 50 })
let loadTimer

const onRouteChangeStart = () => {
  loadTimer = setTimeout(() => {
    NProgress.start()
  }, 250)
}

const onRouteChangeComplete = (e) => {
  NProgress.done()
  clearTimeout(loadTimer)
}

Router.events.on("routeChangeStart", onRouteChangeStart)
Router.events.on("routeChangeComplete", onRouteChangeComplete)
Router.events.on("routeChangeError", onRouteChangeComplete)

const App = ({ Component, pageProps, err }: AppProps & { err: Error }) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Component {...pageProps} err={err} />
    </ChakraProvider>
  )
}
export default App
