export default {
  brand: "#C8102E",
  primary: "#215BF0",
  dark: "#000000",
  dark50: "rgba(0, 0, 0, 0.5)",
  darkBackground: "#242425",
  divider: "rgba(0, 0, 0, 0.1)",
  midAccessible: "#63666A",
  midLight: "#B1B5B8",
  midLight50: "rgba(177, 181, 184, 0.5)",
  lightest: "rgba(0, 28, 65, 0.03)",
  light: "rgba(0, 28, 65, 0.05)",
  lightDarker: "rgba(0, 28, 65, 0.07)",
  lightDarkest: "rgba(0, 28, 65, 0.09)",
  error: "#FF4C35",
  errorLight: "rgba(255, 76, 53, 0.5)",
  errorLightest: "rgba(255, 76, 53, 0.05)",
  disabled: "#D3D7D9",
  success: "#00CB51",
  focus: "rgba(33, 91, 240, 0.33)",
  white: "#FFF",
  gray: "#F2F4F6",
  grayLight: "#F9F9F9",
  buttonBorder: "rgba(177, 181, 184, 0.5)",
  blackButtonFocus: "#333333",
}
