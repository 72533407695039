import { css } from "@emotion/react"
import * as R from "ramda"

type Typography =
  | "display1"
  | "display2"
  | "display3"
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6"
  | "smallCapsLarge"
  | "smallCapsSmall"
  | "paragraphXl"
  | "paragraphLarge"
  | "paragraphNormal"
  | "bodyNormal"
  | "bodySmall"
  | "bodyXs"
const bold =
  "display1" ||
  "display2" ||
  "display3" ||
  "heading1" ||
  "heading2" ||
  "heading3" ||
  "heading4" ||
  "heading5" ||
  "heading6" ||
  "smallCapsLarge" ||
  "smallCapsSmall"

export const letterSpacings = {
  smallCapsLarge: "0.05rem",
  smallCapsSmall: "0.063rem",
}

export const fontSizes = {
  display1: "5.156rem",
  display2: "4.313rem",
  display3: "3.563rem",
  heading1: "3rem",
  heading2: "2.5rem",
  heading3: "2.063rem",
  heading4: "1.719rem",
  heading5: "1.438rem",
  heading6: "1.188rem",
  smallCapsLarge: "0.844rem",
  smallCapsSmall: "0.71875rem",
  paragraphXl: "1.438rem",
  paragraphLarge: "1.188rem",
  paragraphNormal: "1rem",
  bodyNormal: "1rem",
  bodySmall: "0.844rem",
  bodyXs: "0.688rem",
}

export const lineHeights = {
  display1: "6.5rem",
  display2: "5.25rem",
  display3: "4.5rem",
  heading1: "3.75rem",
  heading2: "3.25rem",
  heading3: "2.5rem",
  heading4: "2.25rem",
  heading5: "1.875rem",
  heading6: "1.5rem",
  smallCapsLarge: "1rem",
  smallCapsSmall: "1rem",
  paragraphXl: "1.875rem",
  paragraphLarge: "1.625rem",
  paragraphNormal: "1.375rem",
  bodyNormal: "1.25rem",
  bodySmall: "1rem",
  bodyXs: "1rem",
}

export const typographyHelper = (type: Typography) =>
  R.reject(R.isNil)({
    letterSpacings: letterSpacings[type],
    fontSizes: fontSizes[type],
    lineHeights: lineHeights[type],
    fontWeight: type === bold ? "medium" : "normal",
  })

export const typographyHelperCss = (type: Typography) =>
  css(
    R.reject(R.isNil)({
      letterSpacing: letterSpacings[type],
      fontSize: fontSizes[type],
      lineHeight: lineHeights[type],
      fontWeight: type === bold ? "medium" : "normal",
    })
  )
