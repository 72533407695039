import { theme } from "@chakra-ui/react"

export default {
  ...theme.space,
  xss: ".25rem",
  xs: ".5rem",
  s: ".75rem",
  m: "1rem",
  lg: "1.25rem",
  xl: "2.5rem",
  "2xl": "3.75rem",
  "3xl": "5rem",
  "4xl": "7.5rem",
}
